<template>
  <div class="appointments-dasboard mb-5 p-1 pt-0 card-sidebar">
    <h2 class="title w-full pb-2">Agenda Semanal</h2>
    <div class="">
      <ListDayAppointments :dashboard="true" calendar-view="listWeek" />
    </div>
    <div class="text-right mb-3 mt-3">
      <router-link
        class="see_more cursor-pointer py-1 px-4 text-xs ml-auto text-center"
        :to="{ name: 'appointments' }"
      >
        Ver Agenda</router-link
      >
    </div>
  </div>
</template>
<script setup>
import { defineAsyncComponent } from "vue";

const ListDayAppointments = defineAsyncComponent(() =>
  import("../appointments/ListApointments.vue")
);
</script>
